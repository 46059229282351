// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='m-10 mt-20 p-20 w-full h-96'>
      <h2>404 - Page Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>Go back to <ul><Link to="/" className='text-custom-blue hover:text-red-400'>Home</Link></ul></p>
    </div>
  );
};

export default NotFound;

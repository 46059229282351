import React, { useEffect } from 'react';
import { FaUserMd, FaGlobe, FaHospitalAlt, FaClipboardCheck, FaHandshake, FaChartLine, FaUserTie } from 'react-icons/fa';

const Talent = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const employeeLink = "https://forms.gle/Y6VCD2VxWVCeE2kK8"



    return (
        <div className="bg-gray-50 py-16 px-6 lg:px-8 mt-20">
            {/* Hero Section */}
            <div className="max-w-7xl mx-auto mb-12 text-center bg-custom-blue text-white p-20">
                <h1 className="text-4xl font-extrabold text-white mb-4">Empowering Talent to Work Globally</h1>
                <p className="text-lg text-white leading-relaxed mb-8">
                    At <span className="font-semibold">Chainlink Technology Pvt Ltd</span>, we bridge the gap between skilled healthcare professionals and high-demand regions in Europe and the Middle East.
                </p>
                {/* <button onClick={()=>{}} className="bg-white text-custom-blue font-bold py-3 px-6 rounded-lg hover:bg-gray-300 transition duration-300">
                    Submit Your Application
                </button> */}

                <a
                    href={employeeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-white border-custom-blue border-2 text-custom-blue font-normal py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
                >Submit Your Application</a>
            </div>

            {/* Key Offerings Section */}
            <div className="max-w-7xl mx-auto mb-16">
                <h2 className="text-3xl font-semibold text-gray-800 mb-8 text-center">Key Offerings</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {/* Offering 1 */}
                    <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
                        <FaUserMd className="text-4xl text-custom-blue mb-4" />
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Healthcare Professional Recruitment</h3>
                        <p className="text-gray-600">
                            Sourcing and placing top-tier healthcare professionals with hospitals and clinics across Europe and the Middle East.
                        </p>
                    </div>

                    {/* Offering 2 */}
                    <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
                        <FaGlobe className="text-4xl text-custom-blue mb-4" />
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">International Placement Support</h3>
                        <p className="text-gray-600">
                            End-to-end support including licensing, visa processing, and relocation assistance.
                        </p>
                    </div>

                    {/* Offering 3 */}
                    <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
                        <FaHospitalAlt className="text-4xl text-custom-blue mb-4" />
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Customized Solutions</h3>
                        <p className="text-gray-600">
                            Tailored services to meet the unique needs of each healthcare organization.
                        </p>
                    </div>

                    {/* Offering 4 */}
                    <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
                        <FaClipboardCheck className="text-4xl text-custom-blue mb-4" />
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Training and Compliance</h3>
                        <p className="text-gray-600">
                            Training and certification support to meet specific professional standards.
                        </p>
                    </div>
                </div>
            </div>

            {/* Benefits Section */}
            <div className="max-w-7xl mx-auto mb-16">
                <h2 className="text-3xl font-semibold text-gray-800 mb-8 text-center">Benefits of Our Services</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <FaHandshake className="text-3xl text-custom-blue mb-4" />
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Expert Guidance</h3>
                        <p className="text-gray-600">Benefit from our years of experience in international healthcare recruitment.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <FaChartLine className="text-3xl text-custom-blue mb-4" />
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Career Growth</h3>
                        <p className="text-gray-600">Access to high-quality job opportunities and career advancement paths.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <FaUserTie className="text-3xl text-custom-blue mb-4" />
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Personalized Support</h3>
                        <p className="text-gray-600">Dedicated assistance throughout your journey, from application to placement.</p>
                    </div>
                </div>
            </div>

            {/* Transparency Section */}
            <div className="max-w-7xl mx-auto mb-16 bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Our Commitment to Transparency</h2>
                <p className="text-lg text-gray-600 leading-relaxed mb-8 text-center max-w-3xl mx-auto">
                    At Chainlink Technology, we believe in complete transparency throughout the recruitment process. We provide:
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-blue-50 p-6 rounded-lg shadow-md">
                        <div className="flex items-center mb-3">
                            <svg className="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                            <h3 className="text-xl font-semibold text-gray-800">Clear Communication</h3>
                        </div>
                        <p className="text-gray-600">At every stage of the process, ensuring you're always informed.</p>
                    </div>
                    <div className="bg-green-50 p-6 rounded-lg shadow-md">
                        <div className="flex items-center mb-3">
                            <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <h3 className="text-xl font-semibold text-gray-800">Regular Updates</h3>
                        </div>
                        <p className="text-gray-600">Keeping you informed about your application status consistently.</p>
                    </div>
                    <div className="bg-purple-50 p-6 rounded-lg shadow-md">
                        <div className="flex items-center mb-3">
                            <svg className="w-6 h-6 text-purple-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <h3 className="text-xl font-semibold text-gray-800">Honest Feedback</h3>
                        </div>
                        <p className="text-gray-600">Providing constructive feedback and guidance for your improvement.</p>
                    </div>
                    <div className="bg-orange-50 p-6 rounded-lg shadow-md">
                        <div className="flex items-center mb-3">
                            <svg className="w-6 h-6 text-orange-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <h3 className="text-xl font-semibold text-gray-800">Full Disclosure</h3>
                        </div>
                        <p className="text-gray-600">Clearly stating all job requirements and expectations upfront.</p>
                    </div>
                </div>
            </div>

            {/* Clear Process Section */}
            <div className="max-w-7xl mx-auto mb-16">
                <h2 className="text-3xl font-semibold text-gray-800 mb-8 text-center">Our Clear Process</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="text-2xl font-bold text-custom-blue mb-4">Step 1</div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Application</h3>
                        <p className="text-gray-600">Submit your application and credentials for review.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="text-2xl font-bold text-custom-blue mb-4">Step 2</div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Assessment</h3>
                        <p className="text-gray-600">We evaluate your qualifications and match you with suitable opportunities.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="text-2xl font-bold text-custom-blue mb-4">Step 3</div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Preparation</h3>
                        <p className="text-gray-600">We assist with necessary documentation and training.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="text-2xl font-bold text-custom-blue mb-4">Step 4</div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Placement</h3>
                        <p className="text-gray-600">We support your transition to your new role and location.</p>
                    </div>
                </div>
            </div>

            {/* Transparent Costing Section */}
            <div className="max-w-7xl mx-auto mb-16 bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold text-gray-800 mb-4 text-center">Transparent Costing</h2>
                <p className="text-lg text-gray-600 leading-relaxed mb-6">
                    We believe in full transparency when it comes to our fees and costs:
                </p>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                    <li>No hidden charges or unexpected fees</li>
                    <li>Clear breakdown of all costs involved in the recruitment process</li>
                    <li>Upfront information about any third-party fees (e.g., visa processing, certification)</li>
                    <li>Flexible payment options to suit your needs</li>
                </ul>
                <div className="mt-6 text-center">
                    <a
                        href={employeeLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-white border-custom-blue border-2 text-custom-blue font-normal py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
                    >Submit Your Application</a>
                </div>
            </div>


        </div>
    );
};

export default Talent;
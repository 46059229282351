import React, { useEffect } from 'react';

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []);


    return (
        <div className="min-h-screen py-10 px-4 lg:px-20 bg-gray-50 mt-20">
            <div className="container mx-auto bg-white shadow-md rounded-lg p-8">
                <h1 className="text-4xl font-bold text-custom-blue mb-6 text-center">Terms & Conditions</h1>
                <p className="text-gray-700 text-lg mb-4">
                    Welcome to TalentFirst. By using our services, you agree to comply with and be bound by the following terms and conditions.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">1. Acceptance of Terms</h2>
                <p className="text-gray-700 text-lg mb-4">
                    By accessing or using TalentFirst's website and services, you agree to be bound by these Terms & Conditions and our Privacy Policy.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">2. User Responsibilities</h2>
                <ul className="list-disc list-inside text-gray-700 text-lg mb-4">
                    <li>Provide accurate and complete information when creating an account or submitting job applications.</li>
                    <li>Maintain the confidentiality of your account credentials.</li>
                    <li>Use our services in compliance with all applicable laws and regulations.</li>
                </ul>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">3. Job Postings and Applications</h2>
                <p className="text-gray-700 text-lg mb-4">
                    TalentFirst acts as an intermediary between employers and job seekers. We do not guarantee employment or the accuracy of job postings.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">4. Intellectual Property</h2>
                <p className="text-gray-700 text-lg mb-4">
                    All content on the TalentFirst website is the property of TalentFirst or its licensors and is protected by copyright and other intellectual property laws.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">5. Limitation of Liability</h2>
                <p className="text-gray-700 text-lg mb-4">
                    TalentFirst shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">6. Privacy</h2>
                <p className="text-gray-700 text-lg mb-4">
                    Your use of TalentFirst is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your information.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">7. Modifications to Terms</h2>
                <p className="text-gray-700 text-lg mb-4">
                    TalentFirst reserves the right to modify these Terms & Conditions at any time. We will notify users of any significant changes.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">8. Termination</h2>
                <p className="text-gray-700 text-lg mb-4">
                    We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms & Conditions or is harmful to other users, us, or third parties, or for any other reason.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">9. Governing Law</h2>
                <p className="text-gray-700 text-lg mb-4">
                    These Terms & Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">10. Contact Us</h2>
                <p className="text-gray-700 text-lg mb-4">
                    If you have any questions about these Terms & Conditions, please contact us at:
                </p>
                <ul className="list-none text-gray-700 text-lg mb-4">
                    <li>Email: <a href="mailto:legal@talentfirst.global" className="text-blue-500 hover:underline">connect@talentfirst.global</a></li>
                    <li>Phone: <a href="tel:+917975563057" className='text-blue-500'>+91-7975563057</a></li>
                    <li>Address: 29/A, 27th Main Rd, Sector 5, 11th Sector, HSR Layout, Bengaluru, Karnataka 560102</li>
                </ul>
            </div>
        </div>
    );
};

export default TermsAndConditions;
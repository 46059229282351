// Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/logo-highdef.png";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="bg-white text-custom-blue fixed top-0 left-0 w-full shadow-lg z-50">
            <div className="container mx-auto flex justify-between items-center px-4 py-3">
                {/* Logo */}
                <div className="flex items-center">
                    <Link to="/">
                        <img src={logo} alt="TalentFirst Logo" className="h-12 w-auto" />
                    </Link>
                </div>

                {/* Desktop Navigation */}
                <nav className="hidden md:flex space-x-6 font-medium">
                    <Link to="/" className="hover:text-gray-500">Home</Link>
                    <Link to="/about" className="hover:text-gray-500">About Us</Link>
                    <Link to="/employer" className="hover:text-gray-500">Employers</Link>
                    <Link to="/talent" className="hover:text-gray-500">Talent</Link>
                    <Link to="/contact" className="hover:text-gray-500">Contact Us</Link>
                </nav>

                {/* Mobile Menu Button */}
                <div className="md:hidden flex items-center">
                    <button onClick={toggleMenu} className="focus:outline-none">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>

            {/* Mobile Navigation */}
            {menuOpen && (
                <nav className="md:hidden bg-white text-custom-blue">
                    <ul className="flex flex-col space-y-2 px-4 py-2">
                        <li>
                            <Link to="/" onClick={toggleMenu} className="block hover:bg-blue-100 p-2">Home</Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={toggleMenu} className="block hover:bg-blue-100 p-2">About Us</Link>
                        </li>
                        <li>
                            <Link to="/employer" onClick={toggleMenu} className="block hover:bg-blue-100 p-2">Employers</Link>
                        </li>
                        <li>
                            <Link to="/talent" onClick={toggleMenu} className="block hover:bg-blue-100 p-2">Talent</Link>
                        </li>
                        <li>
                            <Link to="/contact" onClick={toggleMenu} className="block hover:bg-blue-100 p-2">Contact Us</Link>
                        </li>
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default Header;

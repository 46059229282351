import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Talent from "./pages/Talent";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import About from "./pages/About";
import Employer from "./pages/Employer";
import TermsConditions from "./pages/TermsConditions";
import Footer from "./Footer"
import NotFound from "./pages/NotFound";



function App() {
  return (
    <div className=" m-0 p-0">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/employer" element={<Employer />} />
          <Route path="/talent" element={<Talent />} />
          <Route path="/terms&conditions" element={<TermsConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
        <Footer />
      </Router>

    </div>
  );
}

export default App;

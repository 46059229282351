// import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { FaClipboardList, FaUserCheck, FaHandshake } from 'react-icons/fa'; // Import icons from react-icons


// const Employer = () => {
//     useEffect(() => {
//         window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
//     }, []);

//     const googleFormLink = 'https://forms.gle/3A5HELdYoEfLyCRHA';

//     return (
//         <div className="min-h-screen">
//             {/* Hero Section */}
//             <section className="bg-custom-blue text-white py-20 text-center mt-20">
//                 <div className="container mx-auto px-4">
//                     <h1 className="text-4xl font-bold mb-4">Find the Best Talent for Your Business</h1>
//                     <p className="text-lg mb-8">We connect you with skilled professionals to help your business grow.</p>
//                     <a
//                         href={googleFormLink}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         className="bg-white border-custom-blue border-2 text-custom-blue font-normal py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
//                     >
//                         Submit Requirements
//                     </a>
//                 </div>
//             </section>

//             {/* Why Choose Us Section */}
//             <section className="py-16 bg-gray-100 text-center">
//                 <div className="container mx-auto px-4">
//                     <h2 className="text-3xl font-bold mb-8">Why Choose Us?</h2>
//                     <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//                         <div className="bg-white p-6 rounded-lg shadow-md">
//                             <h3 className="text-xl font-semibold mb-4">Quick Hiring Process</h3>
//                             <p className="text-gray-700">Our streamlined process helps you hire the best candidates quickly and efficiently.</p>
//                         </div>
//                         <div className="bg-white p-6 rounded-lg shadow-md">
//                             <h3 className="text-xl font-semibold mb-4">Access to Top Talent</h3>
//                             <p className="text-gray-700">Gain access to a vast pool of skilled professionals ready to meet your needs.</p>
//                         </div>
//                         <div className="bg-white p-6 rounded-lg shadow-md">
//                             <h3 className="text-xl font-semibold mb-4">Flexible Hiring Options</h3>
//                             <p className="text-gray-700">Whether you need permanent, temporary, or contract staff, we have the solution for you.</p>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             {/* Enhanced How It Works Section with Icons */}
//             <section className="py-16 text-center bg-white">
//                 <div className="container mx-auto px-4">
//                     <h2 className="text-3xl font-bold mb-8">How It Works</h2>
//                     <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//                         <div className="p-6 group transform hover:scale-105 transition duration-300">
//                             <FaClipboardList className="text-custom-blue text-6xl mx-auto mb-4 group-hover:text-blue-800 transition duration-300" />
//                             <h3 className="text-xl font-semibold mb-4">1. Submit Your Requirement</h3>
//                             <p className="text-gray-700">Tell us your hiring needs by submitting a detailed requirement form.</p>
//                         </div>
//                         <div className="p-6 group transform hover:scale-105 transition duration-300">
//                             <FaUserCheck className="text-custom-blue text-6xl mx-auto mb-4 group-hover:text-blue-800 transition duration-300" />
//                             <h3 className="text-xl font-semibold mb-4">2. Review Candidates</h3>
//                             <p className="text-gray-700">Our team will present you with a curated list of qualified candidates.</p>
//                         </div>
//                         <div className="p-6 group transform hover:scale-105 transition duration-300">
//                             <FaHandshake className="text-custom-blue text-6xl mx-auto mb-4 group-hover:text-blue-800 transition duration-300" />
//                             <h3 className="text-xl font-semibold mb-4">3. Hire the Best Fit</h3>
//                             <p className="text-gray-700">Select the right candidate and hire them quickly and efficiently.</p>
//                         </div>
//                     </div>

//                 </div>
//             </section>

//             {/* Submit Requirement Section */}
//             <section className="py-16 bg-gray-100 text-center">
//                 <div className="container mx-auto px-4">
//                     <h2 className="text-3xl font-bold mb-8">Submit Your Hiring Requirement</h2>
//                     <p className="text-lg mb-8">Fill out the form below to let us know your hiring needs, and our team will get back to you with the best candidates for the job.</p>
//                     <a
//                         href={googleFormLink}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         className="bg-white border-custom-blue border-2 text-custom-blue font-normal py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
//                     >
//                         Submit Requirements
//                     </a>
//                 </div>
//             </section>
//         </div>
//     );
// };

// export default Employer;


import React, { useEffect } from 'react';
import { FaClipboardList, FaUserCheck, FaHandshake } from 'react-icons/fa'; // Import icons from react-icons

const Employer = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []);

    const googleFormLink = 'https://forms.gle/3A5HELdYoEfLyCRHA';

    return (
        <div className="min-h-screen">
            {/* Hero Section */}
            <section className="bg-custom-blue text-white py-20 text-center mt-20">
                <div className="container mx-auto px-4">
                    <h1 className="text-5xl font-extrabold mb-6">Find the Best Talent for Your Business</h1>
                    <p className="text-xl mb-8">We connect you with skilled professionals to help your business grow and succeed.</p>
                    <a
                        href={googleFormLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-white border-2 border-white text-custom-blue font-semibold py-3 px-8 rounded-full hover:bg-gray-100 transition duration-300"
                    >
                        Submit Your Requirements
                    </a>
                </div>
            </section>

            {/* Why Choose Us Section */}
            <section className="py-16 bg-gray-50 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-10">Why Choose Us?</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
                            <h3 className="text-2xl font-semibold mb-4">Quick Hiring Process</h3>
                            <p className="text-gray-700">Our streamlined hiring process helps you find the best candidates quickly and efficiently, minimizing downtime.</p>
                        </div>
                        <div className="bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
                            <h3 className="text-2xl font-semibold mb-4">Access to Top Talent</h3>
                            <p className="text-gray-700">Gain access to a vast pool of skilled professionals who are ready to meet your business needs.</p>
                        </div>
                        <div className="bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
                            <h3 className="text-2xl font-semibold mb-4">Flexible Hiring Options</h3>
                            <p className="text-gray-700">Whether you need permanent, temporary, or contract staff, we provide solutions tailored to your requirements.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* How It Works Section with Enhanced Icons */}
            <section className="py-16 text-center bg-custome-gray">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-10 text-white">How It Works</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        <div className="p-8 group transform hover:scale-105 transition duration-300">
                            <FaClipboardList className="text-white text-6xl mx-auto mb-4 group-hover:custom-blue-800 transition duration-300" />
                            <h3 className="text-2xl text-white font-semibold mb-4">1. Submit Your Requirement</h3>
                            <p className="text-gray-300">Tell us your hiring needs by submitting a detailed requirement form.</p>
                        </div>
                        <div className="p-8 group transform hover:scale-105 transition duration-300">
                            <FaUserCheck className="text-white text-6xl mx-auto mb-4 group-hover:text-custom-blue-800 transition duration-300" />
                            <h3 className="text-2xl text-white font-semibold mb-4">2. Review Candidates</h3>
                            <p className="text-gray-300">Our team will present you with a curated list of qualified candidates based on your requirements.</p>
                        </div>
                        <div className="p-8 group transform hover:scale-105 transition duration-300">
                            <FaHandshake className="text-white text-6xl mx-auto mb-4 group-hover:text-custom-blue-800 transition duration-300" />
                            <h3 className="text-2xl text-white font-semibold mb-4">3. Hire the Best Fit</h3>
                            <p className="text-gray-300">Select the right candidate and complete the hiring process quickly and efficiently.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Submit Requirement Section */}
            <section className="py-16 bg-gray-50 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-8">Submit Your Hiring Requirement</h2>
                    <p className="text-lg mb-8">Fill out the form below to let us know your hiring needs, and our team will get back to you with the best candidates for the job.</p>
                    <a
                        href={googleFormLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-custom-blue text-white font-semibold py-3 px-8 rounded-full hover:bg-blue-800 transition duration-300"
                    >
                        Submit Your Requirements
                    </a>
                </div>
            </section>
        </div>
    );
};

export default Employer;

// import React, { useEffect, useState } from 'react';

// const Contact = () => {
//     // State to manage form data
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         subject: '',
//         message: ''
//     });

//     // State to manage form submission status
//     const [formSubmitted, setFormSubmitted] = useState(false);

//     // Handle input changes
//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     // Handle form submission
//     const handleSubmit = async (e) => {
//         e.preventDefault(); 


//         const apiUrl = process.env.REACT_APP_API_URL;
//             const fetchUrl = `${apiUrl}/api/emaillist`;

//         // const apiUrl = "http://localhost:5001"


//         const dataToSend = {
//             name: formData.name,
//             email: formData.email,
//             subject: formData.subject,
//             message: formData.message
//         };

//         try {

//             const response = await fetch(`${apiUrl}/api/contact`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify(dataToSend)
//             });


//             if (response.ok) {
      
//                 console.log('Form Data Submitted Successfully:', formData);
//                 setFormSubmitted(true);
//                 setFormData({ name: '', email: '', subject: '', message: '' }); 
//             } else {

//                 console.error('Error submitting form:', response.statusText);
//                 alert('There was an issue submitting the form. Please try again.');
//             }
//         } catch (error) {
//             // If there is an error in the fetch request, log the error
//             console.error('Network error:', error);
//             alert('There was a network error. Please try again later.');
//         }
//     };

//     useEffect(() => {
//         window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
//     }, []);

//     return (
//         <div className="bg-gray-100 min-h-screen py-10 flex justify-center items-center mt-20">
//             <div className="container mx-auto px-4">
//                 <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl mx-auto">
//                     <h1 className="text-3xl lg:text-4xl font-bold text-custom-blue mb-8 text-center">Contact Us</h1>

//                     <div className="flex flex-col lg:flex-row justify-between items-start lg:space-x-8 mb-8">
//                         {/* Address Section */}
//                         <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
//                             <h2 className="text-2xl font-semibold mb-4">Our Office</h2>
//                             <p className="text-gray-700 text-xl mb-1 font-semibold">Chainlink Technology Pvt Ltd</p> {/* Company Name */}
//                             <p className="text-gray-700 text-lg mb-1 font-semibold mt-2">Nasscom COE-IOT</p>
//                             <p className="text-gray-700 text-lg mb-2">
//                                 29/A, 27th Main Rd, Sector 5,<br />
//                                 11st Sector, HSR Layout,<br />
//                                 Bengaluru, Karnataka 560102<br />
//                             </p>
//                             <p className="text-gray-700 text-lg mb-2">
//                                 Phone: +91-7975563057
//                             </p>
//                             <p className="text-gray-700 text-lg">
//                                 Email: <a href="mailto:connect@talentfirst.global" className="hover:underline text-custom-blue">connect@talentfirst.global</a>
//                             </p>
//                         </div>

//                         {/* Conditional Rendering of Contact Form or Success Message */}
//                         <div className="w-full lg:w-1/2">
//                             {formSubmitted ? (
//                                 // Success Message
//                                 <div className="text-center py-10">
//                                     <h2 className="text-2xl font-semibold mb-4 text-green-600">Thank You!</h2>
//                                     <p className="text-gray-700">Your message has been successfully sent. We will get back to you soon.</p>
//                                     <button
//                                         className="mt-6 bg-custom-blue text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-800 focus:outline-none"
//                                         onClick={() => setFormSubmitted(false)} // Reset form submission status
//                                     >
//                                         Send Another Message
//                                     </button>
//                                 </div>
//                             ) : (
//                                 // Contact Form
//                                 <form onSubmit={handleSubmit} className="space-y-4">
//                                     <div>
//                                         <label htmlFor="name" className="block text-gray-700 font-semibold mb-1">Name</label>
//                                         <input
//                                             type="text"
//                                             id="name"
//                                             name="name"
//                                             value={formData.name}
//                                             onChange={handleChange}
//                                             className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
//                                             required
//                                         />
//                                     </div>
//                                     <div>
//                                         <label htmlFor="email" className="block text-gray-700 font-semibold mb-1">Email</label>
//                                         <input
//                                             type="email"
//                                             id="email"
//                                             name="email"
//                                             value={formData.email}
//                                             onChange={handleChange}
//                                             className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
//                                             required
//                                         />
//                                     </div>
//                                     <div>
//                                         <label htmlFor="subject" className="block text-gray-700 font-semibold mb-1">Subject</label>
//                                         <input
//                                             type="text"
//                                             id="subject"
//                                             name="subject"
//                                             value={formData.subject}
//                                             onChange={handleChange}
//                                             className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
//                                             required
//                                         />
//                                     </div>
//                                     <div>
//                                         <label htmlFor="message" className="block text-gray-700 font-semibold mb-1">Message</label>
//                                         <textarea
//                                             id="message"
//                                             name="message"
//                                             value={formData.message}
//                                             onChange={handleChange}
//                                             className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
//                                             rows="4"
//                                             required
//                                         ></textarea>
//                                     </div>
//                                     <div className="text-center">
//                                         <button
//                                             type="submit"
//                                             className="bg-custom-blue text-white px-6 py-2 rounded-lg font-semibold hover:bg-custom-blue focus:outline-none focus:ring-2 focus:ring-custom-blue focus:ring-opacity-50"
//                                         >
//                                             Send Message
//                                         </button>
//                                     </div>
//                                 </form>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Contact;

import React, { useEffect, useState } from 'react';

const Contact = () => {
    // State to manage form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    // State to manage form submission status and trigger
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [shouldSubmit, setShouldSubmit] = useState(false);

    // Handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle form submission to trigger useEffect
    const handleSubmit = (e) => {
        e.preventDefault(); 
        setShouldSubmit(true); // Set trigger for submission
    };

    // useEffect to handle fetch when form is submitted
    useEffect(() => {
        const sendFormData = async () => {
            if (shouldSubmit) { // Check if form should be submitted
                const apiUrl = process.env.REACT_APP_API_URL;

                const dataToSend = {
                    name: formData.name,
                    email: formData.email,
                    subject: formData.subject,
                    message: formData.message
                };

                try {
                    const response = await fetch(`${apiUrl}/api/contact`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(dataToSend)
                    });

                    if (response.ok) {
                        console.log('Form Data Submitted Successfully:', formData);
                        setFormSubmitted(true);
                        setFormData({ name: '', email: '', subject: '', message: '' });
                    } else {
                        console.error('Error submitting form:', response.statusText);
                        alert('There was an issue submitting the form. Please try again.');
                    }
                } catch (error) {
                    console.error('Network error:', error);
                    alert('There was a network error. Please try again later.');
                } finally {
                    setShouldSubmit(false); // Reset the form submission trigger
                }
            }
        };

        sendFormData();
    }, [shouldSubmit, formData]); // Dependency on shouldSubmit and formData

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div className="bg-gray-100 min-h-screen py-10 flex justify-center items-center mt-20">
            <div className="container mx-auto px-4">
                <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl mx-auto">
                    <h1 className="text-3xl lg:text-4xl font-bold text-custom-blue mb-8 text-center">Contact Us</h1>

                    <div className="flex flex-col lg:flex-row justify-between items-start lg:space-x-8 mb-8">
                        {/* Address Section */}
                        <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                            <h2 className="text-2xl font-semibold mb-4">Our Office</h2>
                            <p className="text-gray-700 text-xl mb-1 font-semibold">Chainlink Technology Pvt Ltd</p> {/* Company Name */}
                            <p className="text-gray-700 text-lg mb-1 font-semibold mt-2">Nasscom COE-IOT</p>
                            <p className="text-gray-700 text-lg mb-2">
                                29/A, 27th Main Rd, Sector 5,<br />
                                11st Sector, HSR Layout,<br />
                                Bengaluru, Karnataka 560102<br />
                            </p>
                            <p className="text-gray-700 text-lg mb-2">
                                Phone: +91-7975563057
                            </p>
                            <p className="text-gray-700 text-lg">
                                Email: <a href="mailto:connect@talentfirst.global" className="hover:underline text-custom-blue">connect@talentfirst.global</a>
                            </p>
                        </div>

                        {/* Conditional Rendering of Contact Form or Success Message */}
                        <div className="w-full lg:w-1/2">
                            {formSubmitted ? (
                                // Success Message
                                <div className="text-center py-10">
                                    <h2 className="text-2xl font-semibold mb-4 text-green-600">Thank You!</h2>
                                    <p className="text-gray-700">Your message has been successfully sent. We will get back to you soon.</p>
                                    <button
                                        className="mt-6 bg-custom-blue text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-800 focus:outline-none"
                                        onClick={() => setFormSubmitted(false)} // Reset form submission status
                                    >
                                        Send Another Message
                                    </button>
                                </div>
                            ) : (
                                // Contact Form
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div>
                                        <label htmlFor="name" className="block text-gray-700 font-semibold mb-1">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-gray-700 font-semibold mb-1">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="subject" className="block text-gray-700 font-semibold mb-1">Subject</label>
                                        <input
                                            type="text"
                                            id="subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="message" className="block text-gray-700 font-semibold mb-1">Message</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                                            rows="4"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="bg-custom-blue text-white px-6 py-2 rounded-lg font-semibold hover:bg-custom-blue focus:outline-none focus:ring-2 focus:ring-custom-blue focus:ring-opacity-50"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
